import React, { Component } from "react";
import Headers from "../Header";
import Menu from "../Menu";
import Footer from "../Footer";
import { Link } from "react-router-dom";
export default class Systeminfo extends Component {
    render() {
        return (
            <div>
                <Headers />
                <Menu />
                <div className="content-wrapper" style={{ minHeight: 190 }}>
                    {/*CONTENT CONTAINER*/}
                    {/*===================================================*/}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">
                                        <i className="fas fa-info-circle" /> System Information
                                    </h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                <i className="fas fa-home" /> Admin Panel
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active">System Information</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Page content*/}
                    {/*===================================================*/}
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card card-primary card-outline">
                                        <div className="card-header">
                                            <h3 className="card-title">psec22.byethost22.com</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th>Site Stats &amp; Information</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Response Time</td>
                                                            <td>
                                                                <h5>
                                                                    <span className="badge badge-success">
                                                                        0.022224 sec
                                                                    </span>
                                                                </h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>PHP Configuration File (php.ini)</td>
                                                            <td>
                                                                <h5>
                                                                    <span className="badge badge-warning">
                                                                        /etc/php70/php.ini
                                                                    </span>
                                                                </h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>PHP Error Log</td>
                                                            <td>
                                                                <h5>
                                                                    <span className="badge badge-warning">/log/up</span>
                                                                </h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Zend Version</td>
                                                            <td>
                                                                <h5>
                                                                    <span className="badge badge-danger">3.4.0</span>
                                                                </h5>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Default Timezone</td>
                                                            <td>
                                                                <h5>
                                                                    <span className="badge badge-primary">
                                                                        America/New_York
                                                                    </span>
                                                                </h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="small-box bg-primary">
                                                <div className="inner">
                                                    <h3>499</h3>
                                                    <p>Files</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-file" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="small-box bg-info">
                                                <div className="inner">
                                                    <h3>23</h3>
                                                    <p>Folders</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-folder" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <h3>387</h3>
                                                    <p>Images</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-file-image" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="small-box bg-danger">
                                                <div className="inner">
                                                    <h3>76</h3>
                                                    <p>PHP Files</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fab fa-php" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="small-box bg-info">
                                                <div className="inner">
                                                    <h3>9</h3>
                                                    <p>HTML Files</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas  fa-file-code" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <h3>6</h3>
                                                    <p>CSS Files</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fab fa-css3-alt" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="small-box bg-warning">
                                                <div className="inner">
                                                    <h3>3</h3>
                                                    <p>JSS Files</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fab fa-js" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="info-box bg-info">
                                                <span className="info-box-icon">
                                                    <i className="fas fa-hdd" />
                                                </span>
                                                <div className="info-box-content">
                                                    <span className="info-box-text">STORAGE</span>
                                                    <span className="info-box-number">Total: 0 B</span>
                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar progress-bar-striped progress-bar-animated"
                                                            style={{ width: "NAN%" }}
                                                        />
                                                    </div>
                                                    <span className="progress-description">
                                                        Used: <span className="text-semibold">0 B (NAN%)</span>
                                                        <br />
                                                        <i>Feature not available on this host.</i>{" "}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                                <div className="col-md-6">
                                    <div className="card card-primary card-outline">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                Loaded PHP Extensions -{" "}
                                                <span className="badge badge-primary">46</span>
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <pre className="bg-light">
                                                <ul>
                                                    {"\n"}
                                                    <li>Core</li>
                                                    <li>date</li>
                                                    <li>libxml</li>
                                                    <li>openssl</li>
                                                    <li>pcre</li>
                                                    <li>sqlite3</li>
                                                    <li>zlib</li>
                                                    <li>bcmath</li>
                                                    <li>calendar</li>
                                                    <li>ctype</li>
                                                    <li>curl</li>
                                                    <li>dom</li>
                                                    <li>hash</li>
                                                    <li>fileinfo</li>
                                                    <li>filter</li>
                                                    <li>ftp</li>
                                                    <li>gd</li>
                                                    <li>gettext</li>
                                                    <li>gmp</li>
                                                    <li>SPL</li>
                                                    <li>iconv</li>
                                                    <li>intl</li>
                                                    <li>json</li>
                                                    <li>mbstring</li>
                                                    <li>session</li>
                                                    <li>standard</li>
                                                    <li>mysqlnd</li>
                                                    <li>mysqli</li>
                                                    <li>PDO</li>
                                                    <li>pdo_mysql</li>
                                                    <li>pdo_sqlite</li>
                                                    <li>Phar</li>
                                                    <li>Reflection</li>
                                                    <li>mysql</li>
                                                    <li>SimpleXML</li>
                                                    <li>soap</li>
                                                    <li>exif</li>
                                                    <li>tokenizer</li>
                                                    <li>xml</li>
                                                    <li>xmlreader</li>
                                                    <li>xmlrpc</li>
                                                    <li>xmlwriter</li>
                                                    <li>xsl</li>
                                                    <li>zip</li>
                                                    <li>apache2handler</li>
                                                    <li>ionCube Loader</li>
                                                </ul>
                                            </pre>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div className="col-md-12">
                                    <h3 className="mt-none">Host Information</h3>
                                    <p>System information about the web host.</p>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="text-uppercase mar-btm text-sm">Domain IP</p>
                                                    <i className="fas fa-user fa-3x" />
                                                    <hr />
                                                    <p className="h4 text-thin">185.27.134.52</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="text-uppercase mar-btm text-sm">Country</p>
                                                    <i className="fas fa-globe fa-3x" />
                                                    <hr />
                                                    <p className="h4 text-thin">United Kingdom</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="text-uppercase mar-btm text-sm">
                                                        Server Software
                                                    </p>
                                                    <i className="fas fa-database fa-3x" />
                                                    <hr />
                                                    <p className="h4 text-thin">Apache/ </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="text-uppercase mar-btm text-sm">ISP</p>
                                                    <i className="fas fa-tasks fa-3x" />
                                                    <hr />
                                                    <p className="h4 text-thin">Wildcard UK Limited</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="text-uppercase mar-btm text-sm">Server OS</p>
                                                    <i className="fas fa-desktop fa-3x" />
                                                    <hr />
                                                    <p className="h4 text-thin">Linux</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="text-uppercase mar-btm text-sm">PHP Version</p>
                                                    <i className="fas fa-file-code fa-3x" />
                                                    <hr />
                                                    <p className="h4 text-thin">7.4.8</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="text-uppercase mar-btm text-sm">
                                                        MySQL Version
                                                    </p>
                                                    <i className="fas fa-list-alt fa-3x" />
                                                    <hr />
                                                    <p className="h4 text-thin">5.5.5-10.3.27-MariaDB</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="text-uppercase mar-btm text-sm">Server Port</p>
                                                    <i className="fas fa-plug fa-3x" />
                                                    <hr />
                                                    <p className="h4 text-thin">80</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="text-uppercase mar-btm text-sm">
                                                        OpenSSL Version
                                                    </p>
                                                    <i className="fas fa-lock fa-3x" />
                                                    <hr />
                                                    <p className="h4 text-thin">1.1.1g 21 Apr 2020</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="text-uppercase mar-btm text-sm">
                                                        cURL Extension
                                                    </p>
                                                    <i className="fas fa-link fa-3x" />
                                                    <hr />
                                                    <p className="h4 text-thin">7.71.1</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="text-uppercase mar-btm text-sm">
                                                        HTTP Protocol
                                                    </p>
                                                    <i className="fas fa-hdd fa-3x" />
                                                    <hr />
                                                    <p className="h4 text-thin">HTTP/1.0</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <p className="text-uppercase mar-btm text-sm">
                                                        Gateway Interface
                                                    </p>
                                                    <i className="fas fa-sitemap fa-3x" />
                                                    <hr />
                                                    <p className="h4 text-thin">CGI/1.1</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*===================================================*/}
                        {/*End page content*/}
                    </div>
                    {/*===================================================*/}
                    {/*END CONTENT CONTAINER*/}
                </div>

                <Footer />
            </div>
        );
    }
}
