import React from "react";

export default function Footer() {
  return (
    <footer className="main-footer">
      <strong>
        Copyright © 2023 <a href="http://handsintechnology.com">Hands In Technology </a>
      </strong>
       All rights reserved.
    </footer>
  );
}
