import React, { Component } from "react";
import Headers from "../Header";
import Menu from "../Menu";
import Footer from "../Footer";

export default class Visitordetails extends Component {

    render() {
        return (
            <div>
                <Headers />
                <Menu />
                <div className="content-wrapper">
                    {/*CONTENT CONTAINER*/}
                    {/*===================================================*/}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0 ">
                                        <i className="fas fa-align-justify" /> Visitor Details
                                    </h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="dashboard.php">
                                                <i className="fas fa-home" /> Admin Panel
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active">Visitor Details</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Page content*/}
                    {/*===================================================*/}
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary card-outline">
                                        <div className="card-header">
                                            <h3 className="card-title">Details for Visit #114</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">
                                                            <i className="fas fa-user" /> IP Address
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue="78.187.200.220"
                                                            readOnly=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">
                                                            <i className="fas fa-calendar-alt" /> Date &amp; Time
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue="18 January 2023 at 02:41"
                                                            readOnly=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">
                                                            <i className="fas fa-globe" /> Browser
                                                        </label>
                                                        <div className="input-group mar-btm">
                                                            <span className="input-group-addon">
                                                                <img src="http://psec22.byethost22.com/security/assets/img/icons/browser/chrome.png" />
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue="Google Chrome"
                                                                readOnly=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">
                                                            <i className="fas fa-desktop" /> Operating System
                                                        </label>
                                                        <div className="input-group mar-btm">
                                                            <span className="input-group-addon">
                                                                <img src="http://psec22.byethost22.com/security/assets/img/icons/os/win-6.png" />
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue="Windows 10"
                                                                readOnly=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">
                                                            <i className="fas fa-flag" /> Country
                                                        </label>
                                                        <div className="input-group mar-btm">
                                                            <span className="input-group-addon">
                                                                <img
                                                                    src="http://psec22.byethost22.com/security/assets/plugins/flags/blank.png"
                                                                    className="flag flag-tr"
                                                                    alt="Turkey"
                                                                />
                                                            </span>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue="Turkey"
                                                                readOnly=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">
                                                            <i className="fas fa-map-pin" /> Country Code
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue="TR"
                                                            readOnly=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">
                                                            <i className="fas fa-mobile-alt" /> Device Type
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue="Computer"
                                                            readOnly=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label className="control-label">
                                                            <i className="fas fa-atlas" /> Domain
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue="psec22.byethost22.com"
                                                            readOnly=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <label className="control-label">
                                                        <i className="fas fa-link" /> Visited Page
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="/index.php"
                                                        readOnly=""
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="form-group">
                                                        <label className="control-label">
                                                            <i className="fas fa-robot" /> Bot
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue="
No												"
                                                            readOnly=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="form-group">
                                                        <label className="control-label">
                                                            <i className="fas fa-user-secret" /> User Agent
                                                        </label>
                                                        <textarea
                                                            placeholder="User Agent"
                                                            rows={2}
                                                            className="form-control"
                                                            readOnly=""
                                                            defaultValue={
                                                                "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <label className="control-label">
                                                        <i className="fas fa-reply" /> Referer URL
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="http://psec22.byethost22.com/?i=1"
                                                        readOnly=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*===================================================*/}
                    {/*End page content*/}
                </div>



                <Footer />
            </div>
        );
    }
}
