import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Main from "./components/master/Main";
import Form from "./components/master/Form";
import "../src/App.css";
import Systeminfo from "./components/master/security/Systeminfo";
import Loginsecurity from "./components/master/security/Loginsecurity";
import Warningpages from "./components/master/security/Warningpages";
import Sqlinjection from "./components/master/security/Sqlinjection";
import Ipwhitelist from "./components/master/security/Ipwhitelist";
import Filewhitelist from "./components/master/security/Filewhitelist";
import Portscanner from "./components/master/security/Portscanner";
import Htaccesseditor from "./components/master/security/Htaccesseditor";
import Errormonitoring from "./components/master/security/Errormonitoring";
import Visitanalytics from "./components/master/security/Visitanalytics";
import Livetraffic from "./components/master/security/Livetraffic";
import Visitordetails from "./components/master/security/Visitordetails";
import Phpconfigcheck from "./components/master/security/Phpconfigcheck";
import Phpfunctionscheck from "./components/master/security/Phpfunctionscheck";
import Badwords from "./components/master/security/Badwords";
import Badbots from "./components/master/security/Badbots";
import Proxy from "./components/master/security/Proxy";
import Spam from "./components/master/security/Spam";
import Alllogs from "./components/master/security/Alllogs";
import Spammerlogs from "./components/master/security/Spammerlogs";
import Proxylogs from "./components/master/security/Proxylogs";
import Sqlinjectionlogs from "./components/master/security/Sqlinjectionlogs";
import Badbotlogs from "./components/master/security/Badbotlogs";
import Bansip from "./components/master/security/Bansip";
import Banscountry from "./components/master/security/Banscountry";
import Bansiprange from "./components/master/security/Bansiprange";
import Otherbans from "./components/master/security/Otherbans";
export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/Main">
            <Main/>
          </Route>
          <Route path="/Form">
            <Form/>
          </Route>
          <Route path="/Systeminfo">
            <Systeminfo/>
          </Route>
          <Route path="/Loginsecurity">
            <Loginsecurity/>
          </Route>
          <Route path="/Warningpages">
            <Warningpages/>
          </Route>
          <Route path="/Sqlinjection">
            <Sqlinjection/>
          </Route>
          <Route path="/Ipwhitelist">
            <Ipwhitelist/>
          </Route>
          <Route path="/Filewhitelist">
            <Filewhitelist/>
          </Route>
          <Route path="/Portscanner">
            <Portscanner/>
          </Route>
          <Route path="/Htaccesseditor">
            <Htaccesseditor/>
          </Route>
          <Route path="/Errormonitoring">
            <Errormonitoring/>
          </Route>
          <Route path="/Visitanalytics">
            <Visitanalytics/>
          </Route>
          <Route path="/Livetraffic">
            <Livetraffic/>
          </Route>
          <Route path="/Visitordetails">
            <Visitordetails/>
          </Route>
          <Route path="/Phpconfigcheck">
            <Phpconfigcheck/>
          </Route>
          <Route path="/Phpfunctionscheck">
            <Phpfunctionscheck/>
          </Route>
          <Route path="/Badwords">
            <Badwords/>
          </Route>
          <Route path="/Badbots">
            <Badbots/>
          </Route>
          <Route path="/Proxy">
            <Proxy/>
          </Route>
          <Route path="/Spam">
            <Spam/>
          </Route>
          <Route path="/Alllogs">
            <Alllogs/>
          </Route>
          <Route path="/Spammerlogs">
            <Spammerlogs/>
          </Route>
          <Route path="/Proxylogs">
            <Proxylogs/>
          </Route>
          <Route path="/Sqlinjectionlogs">
            <Sqlinjectionlogs/>
          </Route>
          <Route path="/Badbotlogs">
            <Badbotlogs/>
          </Route>
          <Route path="/Bansip">
            <Bansip/>
          </Route>
          <Route path="/Banscountry">
            <Banscountry/>
          </Route>
          <Route path="/Bansiprange">
            <Bansiprange/>
          </Route>
          <Route path="/Otherbans">
            <Otherbans/>
          </Route>
          <Route exact path="/" component={Main} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}
